/* eslint-disable */
const IEUSignCP = require("./eusw.umd");

class EuSignWrapper {
    constructor() {
        this.euSign = null;
        this.caAddress = 'ca.iit.com.ua';
        this.caPort = '80';
        this.isNeedReadNewKey = true;
        this.privatKeyReaded = false;
        this.roomId = null;
        this.saveUrl = null;
        this.errorNotifyFunc = null;
        this.caList = [];
        this.installedPath = "";
    }

    logEvent(func, text, any = null) {
        console.log(`[${(new Date()).toLocaleTimeString()}]`, '[EventObject]',{
            func: func,
            description: text,
            any: any
        })
    }

    wait(callback) {
        return new Promise((resolve) => {
            callback(() => resolve());
        });
    }

    async loadCryptoLibrary() {
        try {

            this.logEvent('loadCryptoLibrary', 'Start load EUSing library');

            const loader = new IEUSignCP.EndUserLibraryLoader(
                IEUSignCP.EndUserLibraryLoader.LIBRARY_TYPE_DEFAULT,
                'euSign'
            );

            this.logEvent('loadCryptoLibrary', 'Set EUSing library loader', loader);

            await this.wait((runNext) => {
                loader.onload = (library) => {
                    this.euSign = library;
                    runNext();
                };
                loader.onerror = (msg) => {

                    this.logEvent('loadCryptoLibrary|loader.onerror', 'Load error EUSing library', msg);

                    this.errorNotifyFunc('Library load failed. Error - ' + msg);
                };
                loader.load();
            });
        } catch (error) {

            this.logEvent('loadCryptoLibrary', 'General error initialize EUSing library', error);

            this.errorNotifyFunc(error, 'Failed to load crypto library');
        }
    }

    async initializeCryptoLibrary() {
        try {

            this.logEvent('initializeCryptoLibrary', 'Start initialize EUSing library');

            await this.wait((runNext) => {

                this.logEvent('initializeCryptoLibrary|SetUIMode', '');

                this.euSign.SetUIMode(false, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('initializeCryptoLibrary|Initialize', '');

                this.euSign.Initialize(runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('initializeCryptoLibrary|SetUIMode', '');

                this.euSign.SetUIMode(false, runNext, this.errorNotifyFunc);
            });
        } catch (error) {

            this.logEvent('initializeCryptoLibrary', 'Failed to initialize crypto library', error);

            this.errorNotifyFunc(error, 'Failed to initialize crypto library');
        }
    }

    async setSettings(caIndex) {

        this.logEvent('setSettings', 'Start set settings EUSing library');

        const currentCA = this.caList[caIndex]
        try {
            await this.wait((runNext) => {

                this.logEvent('setSettings|SetRuntimeParameter', '', {
                    0: this.euSign.EU_SAVE_SETTINGS_PARAMETER,
                    1: this.euSign.EU_SETTINGS_ID_NONE
                });

                this.euSign.SetRuntimeParameter(
                    this.euSign.EU_SAVE_SETTINGS_PARAMETER,
                    this.euSign.EU_SETTINGS_ID_NONE,
                    runNext,
                    this.errorNotifyFunc
                );
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateFileStoreSettings', '', {
                    SetPath: '',
                    SetAutoRefresh: true,
                    SetSaveLoadedCerts: true,
                    SetExpireTime: 86400,
                    SetCheckCRLs: false,
                    SetOwnCRLsOnly: false,
                    SetAutoDownloadCRLs: true,
                    SetFullAndDeltaCRLs: false,
                });

                const settings = this.euSign.CreateFileStoreSettings();
                settings.SetPath('');
                settings.SetAutoRefresh(true);
                settings.SetSaveLoadedCerts(true);
                settings.SetExpireTime(86400);
                settings.SetCheckCRLs(false);
                settings.SetOwnCRLsOnly(false);
                settings.SetAutoDownloadCRLs(true);
                settings.SetFullAndDeltaCRLs(false);

                this.logEvent('setSettings|SetFileStoreSettings', '', settings);

                this.euSign.SetFileStoreSettings(settings, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {
                this.euSign.GetProxySettings(
                    (proxy) => {
                        // If proxy settings are retrieved successfully
                        this.logEvent('setSettings|GetProxySettings', '', proxy);

                        this.euSign.SetProxySettings(proxy, runNext, this.errorNotifyFunc);
                    },
                    (e) => {
                        // If getting proxy settings fails, try to get system proxy settings
                        this.euSign.GetSystemProxySettings(
                            (proxy) => {
                                this.logEvent('setSettings|GetSystemProxySettings', '', proxy);

                                // If a proxy is to be used but the port is not set
                                if (proxy.GetUseProxy() && proxy.GetPort() === '') {
                                    if (proxy.GetAddress().startsWith("https://")) {
                                        proxy.SetPort("443");
                                    } else {
                                        proxy.SetPort("80");
                                    }
                                }

                                // Set the proxy settings after modifications
                                this.euSign.SetProxySettings(proxy, runNext, this.errorNotifyFunc);
                            },
                            (e) => {
                                // If getting system proxy settings fails, create a new one
                                const proxy = this.euSign.CreateProxySettings();

                                this.logEvent('setSettings|CreateProxySettings', '', {
                                    SetUseProxy: false,
                                    SetAddress: '',
                                    SetPort: '',
                                    SetAnonymous: true,
                                    SetUser: '',
                                    SetSavePassword: false,
                                    SetPassword: '',
                                });

                                proxy.SetUseProxy(false);
                                proxy.SetAddress('');
                                proxy.SetPort('');
                                proxy.SetAnonymous(true);
                                proxy.SetUser('');
                                proxy.SetSavePassword(false);
                                proxy.SetPassword('');

                                // Log the new proxy settings
                                this.logEvent('setSettings|SetProxySettings', '', proxy);

                                // Set the new proxy settings
                                this.euSign.SetProxySettings(proxy, runNext, this.errorNotifyFunc);
                            }
                        );
                    }
                );
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateTSPSettings', '', {
                    SetPort: currentCA?.tspAddressPort ?? null,
                    SetAddress: currentCA?.tspAddress ?? null,
                    SetGetStamps: true,
                });

                const settings = this.euSign.CreateTSPSettings();
                settings.SetGetStamps(true);
                settings.SetAddress(currentCA?.tspAddress ?? null);
                settings.SetPort(currentCA?.tspAddressPort ?? null);

                this.logEvent('setSettings|SetTSPSettings', '', settings);

                this.euSign.SetTSPSettings(settings, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateTSPSettings', '', {
                    SetPort: currentCA?.ocspAccessPointPort ?? null,
                    SetAddress: currentCA?.ocspAccessPointAddress ?? null,
                    SetUseOCSP: true,
                    SetBeforeStore: true,
                });

                const settings = this.euSign.CreateOCSPSettings();
                settings.SetUseOCSP(true);
                settings.SetAddress(currentCA?.ocspAccessPointAddress ?? null);
                settings.SetPort(currentCA?.ocspAccessPointPort ?? null);
                settings.SetBeforeStore(true);

                this.logEvent('setSettings|SetOCSPSettings', '', settings);

                this.euSign.SetOCSPSettings(settings, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateLDAPSettings', '', {
                });

                const settings = this.euSign.CreateLDAPSettings();

                this.logEvent('setSettings|SetLDAPSettings', '', settings);

                this.euSign.SetLDAPSettings(settings, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateCMPSettings', '', {
                    SetPort: currentCA?.caPort ?? null,
                    SetAddress: currentCA?.cmpAddress ?? null,
                    SetUseCMP: currentCA?.cmpAddress && currentCA?.cmpAddress !== "",
                    SetCommonName: '',
                });

                const settings = this.euSign.CreateCMPSettings();
                settings.SetUseCMP(currentCA?.cmpAddress && currentCA?.cmpAddress !== "");
                settings.SetAddress(currentCA?.cmpAddress ?? null);
                settings.SetPort(this.caPort);
                settings.SetCommonName('');

                this.logEvent('setSettings|SetCMPSettings', '', settings);

                this.euSign.SetCMPSettings(settings, runNext, this.errorNotifyFunc);
            });
            await this.wait((runNext) => {

                this.logEvent('setSettings|CreateModeSettings', '', {
                    SetOfflineMode: false,
                });

                const settings = this.euSign.CreateModeSettings();
                settings.SetOfflineMode(false);

                this.logEvent('setSettings|SetModeSettings', '', settings);

                this.euSign.SetModeSettings(settings, runNext, this.errorNotifyFunc);
            });

        } catch (error) {

            this.logEvent('setSettings|error', 'Failed to set library settings', error);

            this.errorNotifyFunc(error, 'Failed to set library settings');
        }
    }

    isFileLarge(Uint8Array) {
        const fileSizeBytes = Uint8Array.length;
        const fileSizeMegabytes = fileSizeBytes / (1024 * 1024);

        this.logEvent('isFileLarge', '', {
            large: fileSizeMegabytes > 100,
            filesize: fileSizeMegabytes,
        });

        return fileSizeMegabytes > 100;
    }

    async getKMTypes() {
        try {
            this.kmTypes = [];

            this.logEvent('getKMTypes', 'Get Key Media Types');

            const _getKeyMediaType = (index, euSign) => {

                this.logEvent('getKMTypes|_getKeyMediaType', 'Get Key Media Types');

                return new Promise((resolve) => {
                    euSign.EnumKeyMediaTypes(index, (type) => {

                        this.logEvent('getKMTypes|_getKeyMediaType|EnumKeyMediaTypes', 'Get Key Media Types', {
                            index: index,
                            type: type,
                        });

                        if (!type) {
                            resolve();
                        } else {
                            this.kmTypes.push(type);
                            _getKeyMediaType(index + 1, euSign, this.errorNotifyFunc).then(resolve);
                        }
                    }, this.errorNotifyFunc);
                });
            };

            await _getKeyMediaType(0, this.euSign, this.errorNotifyFunc);
        } catch (error) {

            this.logEvent('getKMTypes|error', 'Failed to get key media types', error);

            this.errorNotifyFunc(error, 'Failed to get key media types');
        }
    }

    async getKMDevices(typeIndex) {
        try {
            this.kmDevices = [];

            this.logEvent('getKMDevices', 'Get Key Media Devices');

            const _getKeyMediaDevice = (typeIndex, deviceIndex, euSign) => {
                typeIndex = parseInt(typeIndex);
                deviceIndex = parseInt(deviceIndex);

                this.logEvent('getKMDevices|_getKeyMediaDevice', 'Get Key Media Devices', {
                    typeIndex: typeIndex,
                    deviceIndex: deviceIndex,
                });

                return new Promise((resolve) => {
                    euSign.EnumKeyMediaDevices(typeIndex, deviceIndex, (device) => {

                        this.logEvent('getKMDevices|_getKeyMediaDevice|EnumKeyMediaDevices', 'Get Key Media Devices', {
                            typeIndex: typeIndex,
                            deviceIndex: deviceIndex,
                            device: device,
                        });

                        if (!device) {
                            resolve();
                        } else {
                            this.kmDevices.push(device);
                            _getKeyMediaDevice(typeIndex, deviceIndex + 1, euSign, this.errorNotifyFunc).then(resolve);
                        }
                    }, this.errorNotifyFunc);
                });
            };

            await _getKeyMediaDevice(typeIndex, 0, this.euSign, this.errorNotifyFunc);
        } catch (error) {

            this.logEvent('getKMDevices|error', 'Failed to get key media devices', error);

            this.errorNotifyFunc(error, 'Failed to get key media devices');
        }
    }

    async getKeyMediaDevices() {
        try {
            this.kmAllDevices = [];

            this.logEvent('getKeyMediaDevices', 'Get Key Media Devices');

            return new Promise((resolve) => {
                this.euSign.GetKeyMediaDevices(null, (devices) => {

                    this.logEvent('getKeyMediaDevices|GetKeyMediaDevices', 'Get Key Media Devices', {
                        devices: devices,
                    });

                    if (!devices) {
                        resolve();
                    } else {
                        this.kmAllDevices = devices;
                        resolve();
                    }
                }, this.errorNotifyFunc);
            });
        } catch (error) {

            this.logEvent('getKMDevices|error', 'Failed to get key media devices', error);

            this.errorNotifyFunc(error, 'Failed to get key media devices');
        }
    }

    async updateKMTypes() {
        try {
            await this.getKMTypes();
        } catch (error) {
            this.errorNotifyFunc(error, 'Failed to update key media types');
        }
    }

    async updateKMDevices(kmType) {
        try {
            await this.getKMDevices(kmType);
        } catch (error) {
            this.errorNotifyFunc(error, 'Failed to update key media devices');
        }
    }

    async selectFile(defaultFileName, parentFolderName, callback, filter = null, message = null, caption = null) {

        this.logEvent('selectFile', 'Select file with EUSign FileManager', {
            read: true,
            defaultFileName: defaultFileName,
            filter: filter,
            parentFolderName: parentFolderName,
            message: message,
            caption: caption,
        });

        this.euSign.SelectFileEx(true, defaultFileName, filter, parentFolderName, message, caption, callback, this.errorNotifyFunc);
    }

    async createFolder(folderName, callback) {

        this.logEvent('createFolder', 'Create folder by EUSign', {
            folderName: folderName,
        });

        this.euSign.CreateFolder(folderName, callback, this.errorNotifyFunc);
    }

    async writeFile(fileName, folderName, file, callback) {
        let systemSlash = "\\";
        if (!folderName.includes(systemSlash)) {
            systemSlash = "/";
        }

        this.logEvent('writeFile', 'Write file by EUSign', {
            systemSlash: systemSlash,
            folderName: folderName,
            fileName: fileName,
        });

        this.euSign.WriteFile(`${folderName}${systemSlash}${fileName}`, file, callback, this.errorNotifyFunc);
    }

    async deleteFile(fileName, callback) {

        this.logEvent('deleteFile', 'Delete file by EUSign', {
            fileName: fileName,
        });

        this.euSign.DeleteFile(fileName, callback, (err) => {
            console.log(err)
        });
    }

    async readFile(fileName, callback) {

        this.logEvent('readFile', 'Read file by EUSign', {
            fileName: fileName,
        });

        this.euSign.ReadFile(`${fileName}`, callback, this.errorNotifyFunc);
    }

    async getInstallPath(callback) {

        this.logEvent('getInstallPath', 'Get install path by EUSign');

        this.euSign.GetInstallPath(callback, this.errorNotifyFunc);
    }

    async __fileToSignPrepare(file, selectFileCallback, folder = "livecart") {
        this.filePath = '';

        this.logEvent('__fileToSignPrepare', 'Prepare file to sign');
        this.logEvent('__fileToSignPrepare|getInstallPath', 'Run getInstallPath');

        await this.getInstallPath((path) => {
            let systemSlash = "\\";
            if (!path.includes(systemSlash)) {
                systemSlash = "/";
            }
            this.installedPath = `${path}${systemSlash}${folder}`;

            this.logEvent('__fileToSignPrepare|getInstallPath', 'Install path', {
                systemSlash: systemSlash,
                path: this.installedPath,
            });
            this.logEvent('__fileToSignPrepare|getInstallPath|createFolder', 'Run createFolder');

            this.createFolder(`${path}${systemSlash}${folder}`, (createFolderResponse) => {

                this.logEvent('__fileToSignPrepare|getInstallPath|createFolder|writeFile', 'Run writeFile');

                this.writeFile(file.filename, `${path}${systemSlash}${folder}`, file.blob, (writeFileResponse) => {
                    if (file.originalBlob) {

                        this.logEvent('__fileToSignPrepare|getInstallPath|createFolder|writeFile|writeFile', 'Run writeFile');

                        this.writeFile(file.filename.replace('.p7s', ''), `${path}${systemSlash}${folder}`, file.originalBlob, (writeFileOrigianlResponse) => {

                            this.logEvent('__fileToSignPrepare|getInstallPath|createFolder|writeFile|writeFile|selectFile', 'Run selectFile');

                            this.filePath = `${path}${systemSlash}${folder}`;
                            selectFileCallback(`${this.filePath}${systemSlash}${file.filename}`);
                            //this.selectFile(file.filename, `${path}${systemSlash}${folder}`, selectFileCallback, null, `Choose ${file.filename}`, "Livecart")
                        })
                    } else {

                        this.logEvent('__fileToSignPrepare|getInstallPath|createFolder|writeFile|selectFile', 'Run selectFile');

                        this.filePath = `${path}${systemSlash}${folder}`;
                        selectFileCallback(`${this.filePath}${systemSlash}${file.filename}`);
                        //this.selectFile(file.filename, `${path}${systemSlash}${folder}`, selectFileCallback, null, `Choose ${file.filename}`, "Livecart")
                    }
                })

            })
        })
    }

    async WriteHashFile(fileUint8Array, filename, callback, folder = "livecart") {

        this.logEvent('WriteHashFile', 'Write hash', {
            filename: filename,
            folder: folder
        });
        this.logEvent('WriteHashFile|getInstallPath', 'Run getInstallPath');

        this.filePath = '';
        await this.getInstallPath((path) => {
            let systemSlash = "\\";
            if (!path.includes(systemSlash)) {
                systemSlash = "/";
            }
            let name = filename.split(systemSlash)
            name = name[name.length - 1];

            this.logEvent('__fileToSignPrepare|getInstallPath|writeFile', 'Run writeFile');

            this.writeFile(name, `${path}${systemSlash}${folder}`, fileUint8Array, callback)
        })
    }

    async ReadPrivateKey(onSuccess) {
        try {

            this.logEvent('ReadPrivateKey', 'Read private key');
            this.logEvent('ReadPrivateKey|IsPrivateKeyReaded', 'Run IsPrivateKeyReaded');


            this.euSign.IsPrivateKeyReaded((readed) => {
                if (!readed) {
                    this.wait((runNext) => {
                        // Здесь вызывайте функцию ReadPrivateKey вашей библиотеки

                        this.logEvent('ReadPrivateKey|IsPrivateKeyReaded|ReadPrivateKey', 'Run ReadPrivateKey');

                        this.isNeedReadNewKey = false;
                        this.euSign.ReadPrivateKey(runNext, this.errorNotifyFunc);
                    });
                }
                // Успешное выполнение
                onSuccess();
            }, this.errorNotifyFunc);
        } catch (error) {
            // Обработка ошибок
            this.errorNotifyFunc(error);
        }
    }

    async appendSignFile(fileName, fileNameWithPreviousSign, fileNameWithSign, externalSign, onSuccess) {

        this.logEvent('appendSignFile', 'Append sign to file');
        this.logEvent('appendSignFile|AppendSignFile', 'Run AppendSignFile', {
            fileName: fileName,
            fileNameWithPreviousSign: fileNameWithPreviousSign,
            fileNameWithSign: fileNameWithSign,
            externalSign: externalSign,
        });

        await this.euSign.AppendSignFile(
            fileName,
            fileNameWithPreviousSign,
            fileNameWithSign,
            externalSign,
            onSuccess,
            this.errorNotifyFunc
        );
    }

    async SignHash(fileOriginal, fileSign, onSuccess) {

        this.logEvent('SignHash', 'Append sign to file');
        this.logEvent('SignHash|HashFile', 'Run HashFile', {
            fileOriginal: fileOriginal,
            fileSign: fileSign,
        });

        this.euSign.HashFile(
            fileOriginal,
            (hash) => {

                this.logEvent('SignHash|HashFile|SignHash', 'Run SignHash');

                this.euSign.SignHash(
                    hash,
                    (sign) => {
                        const binaryString = window.atob(sign);
                        const length = binaryString.length;
                        const uint8Array = new Uint8Array(length);

                        for (let i = 0; i < length; i++) {
                            uint8Array[i] = binaryString.charCodeAt(i);
                        }

                        this.logEvent('SignHash|HashFile|SignHash|WriteHashFile', 'Run WriteHashFile');

                        this.WriteHashFile(uint8Array, fileSign, onSuccess);
                    },
                    this.errorNotifyFunc
                )
            },
            this.errorNotifyFunc
        );
    }

    async appendSignHash(fileOriginal, fileToSignUint8, fileSign, onSuccess) {

        this.logEvent('appendSignHash', 'Append sign to hash');
        this.logEvent('appendSignHash|HashFile', 'Run HashFile', {
            fileOriginal: fileOriginal,
            fileSign: fileSign,
        });

        let binary = '';
        const len = fileToSignUint8.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(fileToSignUint8[i]);
        }
        binary = btoa(binary);
        this.euSign.HashFile(
            fileOriginal,
            (hash) => {

                this.logEvent('SignHash|HashFile|AppendSignHash', 'Run AppendSignHash');

                this.euSign.AppendSignHash(
                    hash,
                    binary,
                    (sign) => {
                        const binaryString = window.atob(sign);
                        const length = binaryString.length;
                        const uint8Array = new Uint8Array(length);

                        for (let i = 0; i < length; i++) {
                            uint8Array[i] = binaryString.charCodeAt(i);
                        }

                        this.logEvent('SignHash|HashFile|AppendSignHash|WriteHashFile', 'Run WriteHashFile');

                        this.WriteHashFile(uint8Array, fileSign, onSuccess);
                    },
                    this.errorNotifyFunc
                )
            },
            this.errorNotifyFunc
        );
    }

    async saveFile(filename, bytesArray, isHash = false, callback = () => {}) {
        const blob = new Blob([bytesArray], {type: "application/octet-stream"});

        const formData = new FormData();
        formData.append('file', blob, filename);
        formData.append('roomId', this.roomId);
        if (isHash) {
            formData.append('isHash', '1');
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.saveUrl, true);

        xhr.onload = function () {
            if (xhr.status === 200) {
                callback();
            }
        };

        return xhr.send(formData);
    }

    async resetKey() {
        this.euSign.ResetPrivateKey(() => {

            this.logEvent('resetKey|ResetPrivateKey', 'Run ResetPrivateKey');

        }, this.errorNotifyFunc);
    }

    async SignFile(kmTypeIndex, kmDeviceIndex, password, fileToSign, fileSign, filesUint8, useInternalSign, onSuccess) {
        try {
            if (kmTypeIndex < 0 || kmDeviceIndex < 0) {
                this.errorNotifyFunc('Key media type or device not selected.');
                return;
            }

            if (!password || password === '') {
                this.errorNotifyFunc('Password not provided.');
                return;
            }

            if (!fileToSign || fileToSign === '') {
                this.errorNotifyFunc('File to sign not specified.');
                return;
            }

            if (!fileSign || fileSign === '') {
                this.errorNotifyFunc('File sign destination not specified.');
                return;
            }

            this.euSign.ResetPrivateKey(() => {
                    this.euSign.ReadPrivateKeySilently(
                        parseInt(kmTypeIndex),
                        parseInt(kmDeviceIndex),
                        password,
                        async () => {
                            let isSigned = false;
                            let isHash = false;
                            if (fileToSign.includes('.p7s') && !fileToSign.includes('_isHash_')) {
                                isSigned = true;
                            } else if (fileToSign.includes('_isHash_')) {
                                isSigned = true;
                                isHash = true;
                            }

                            if (this.isFileLarge(filesUint8.blob)) {
                                await this.SignHash(
                                    fileToSign.replace('.p7s', ''),
                                    fileSign,
                                    onSuccess
                                );
                            } else if (!isSigned) {

                                this.logEvent('SignFile', 'Sign file');
                                this.logEvent('SignFile|SignFile', 'Run SignFile', {
                                    fileToSign: fileToSign,
                                    fileSign: fileSign,
                                    useInternalSign: !useInternalSign,
                                });

                                await this.euSign.SignFile(
                                    fileToSign,
                                    fileSign,
                                    !useInternalSign,
                                    onSuccess,
                                    this.errorNotifyFunc
                                )
                            } else if (isHash) {
                                await this.appendSignHash(
                                    fileToSign.replace('.p7s', ''),
                                    filesUint8.blob,
                                    fileSign,
                                    onSuccess
                                );
                            } else {
                                await this.appendSignFile(
                                    fileToSign.replace('.p7s', ''),
                                    fileToSign,
                                    fileSign,
                                    !useInternalSign,
                                    onSuccess,
                                    this.errorNotifyFunc);
                            }
                        },
                        this.errorNotifyFunc
                    );
                }, this.errorNotifyFunc
            )

        } catch (error) {
            this.errorNotifyFunc(error);
        }
    }

    async loadCAJson() {
        return new Promise((resolve) => {
            const caPath = "/certs/CAs.json?version=1.0.19";
            const xhr = new XMLHttpRequest();
            const onSuccess = this.setCAJson.bind(this);

            xhr.open('GET', caPath, true);
            xhr.onload = function () {

                if (xhr.status === 200) {
                    onSuccess(xhr.responseText);
                    resolve();
                }
            };

            xhr.send();
        });
    }

    async setCAJson(caJson) {
        const caListSelect = document.getElementById('ca_list');
        this.caList = JSON.parse(caJson.replace(/\\'/g, "'"));
        await this.setCAListSelect(caListSelect, this.caList)
    }

    async setCAListSelect(selectElement, caList) {
        selectElement.innerHTML = '';
        this.caList.forEach((ca, index) => {
            let find = ca?.issuerCNs.filter(str => str.includes(this.issuerCN));
            this.caList[index].set = find.length > 0;
        })
        caList.forEach((item, index) => {
            const optionElement = document.createElement('option');
            optionElement.value = index;
            optionElement.text = item?.issuerCNs[0];
            optionElement.selected = item.set;
            selectElement.appendChild(optionElement);
            if (item.set) {
                this.setCaIndex(index);
            }
        });
    }

    async readKey(type, device, password, setCaIndex) {
        this.setCaIndex = setCaIndex;
        this.euSign.ResetPrivateKey(() => {
                this.euSign.ReadPrivateKeySilently(
                    parseInt(type),
                    parseInt(device),
                    password,
                    async () => {
                        this.privatKeyReaded = true;
                        this.euSign.GetPrivateKeyOwnerInfo(
                            (info) => {
                                if (info.issuerCN) {
                                    this.issuerCN = info.issuerCN;
                                    this.loadCAJson();
                                    console.log(info)
                                }
                            },
                            this.errorNotifyFunc
                        );
                    },
                    this.errorNotifyFunc
                );
            }, this.errorNotifyFunc
        )
    }

    async setDevicesSelect(selectElement, types) {
        selectElement.innerHTML = '';
        types.forEach((type, index) => {
            type.devices.forEach((device, index) => {
                const optionElement = document.createElement('option');
                optionElement.value = `${type.typeIndex}|${device.devIndex}`;
                optionElement.text = device.name;
                selectElement.appendChild(optionElement);
            })
        });
    }
}

module.exports = EuSignWrapper;